import { useMutation, useLazyQuery } from '@apollo/client';
import { IconInnerShadowLeft } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ItemTypeChoices, QuotationPdfQuery, QuotationQuery } from '../../../__generated__/graphql';
import { QUOTATION_PDF_MUTATION } from '../../../api/mutations/quotations/quotation';
import { PROJECT_QUERY } from '../../../api/queries/projects';
import { QUOTATION_PDF_QUERY } from '../../../api/queries/quotations/quotation';
import config from '../../../config';
import { useAppSelector } from '../../../helpers/reduxHooks';
import { classNames } from '../../../helpers/utils';
import { TextArea } from '../../../layout/fields';
import { SelectLayoutPDF } from '../list/fields/group/SelectLayoutPDF';

interface Props {  
  handleClose: () => void,
  quotationPDFData:  QuotationPdfQuery,
  quotationData: QuotationQuery,
}

export default function GeneratePDFModal(props: Props) {
  const { handleClose, quotationPDFData, quotationData } = props;
  const { projectId, quotationId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isFileDownloaded, setIsFileDownloaded] = useState<boolean>(false);
  const [layoutType, setLayoutType] = useState(quotationPDFData.quotationPdf?.response?.versionType);
  const [pdfBodyText, setPdfBodyText] = useState<string>(quotationPDFData.quotationPdf?.response?.body || '');
  const quotationPDFName = useAppSelector(state=>state.quotation.quotationPDFName);
  
  const quotationDataItems = quotationData.quotation?.response?.items || [];
  const selectedGroupsIds = quotationDataItems.filter(item => (item?.itemType === ItemTypeChoices.Group && !item.parentId))?.map(item=>item?.group?.id) || [];
  const [latestQuotationBeforeGeneration, setLatestQuotationBeforeGeneration] = useState(undefined);

  const [getProjectData] = useLazyQuery(PROJECT_QUERY);

  const [
    quotationPdfMutation,
    {
      loading: mutationLoading,
      called: mutationCalled,
    }] = useMutation(QUOTATION_PDF_MUTATION, { 
    refetchQueries: [
      { query: QUOTATION_PDF_QUERY,
        variables: {
          quotation: quotationId as string,
        },
      },
    ],
  });

  const handleGeneratePDF = async () => {
    try {
      setIsLoadingUpdate(true);
  
      const response = await fetch(`${config.GENERATE_PDF_URL}${quotationId}/`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Cookies.get('jwt_token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      setIsFileDownloaded(true);
      close();
      
      const filename = `${quotationPDFName}.pdf`;
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  const handleCreate = () => {

    if (quotationId && layoutType) {

      quotationPdfMutation({ 
        variables: { 
          quotation: quotationId,
          versionType: layoutType,
          body: pdfBodyText,
          id: quotationPDFData.quotationPdf?.response?.id,
          selectedGroups: selectedGroupsIds,
          useAllGroups: true,
        },
        onError: () => handleClose(),
      });

      getProjectData(
        { 
          variables: { project: projectId as string },
          fetchPolicy: 'network-only',
          onCompleted: (data) => {
            const latestQuoationId = data.project?.response?.latestQuotation?.id;
            setLatestQuotationBeforeGeneration(latestQuoationId);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!mutationLoading && mutationCalled) {
      handleGeneratePDF();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationLoading, mutationCalled]);

  useEffect(() => {
    // Redirect user to the newest Quotation if Latest Quotation
    // on QuotationPDF update is not the same as Latest Quotation after PDF generation
    if (isFileDownloaded) {
      setIsLoadingUpdate(true);
      getProjectData(
        { 
          variables: { project: projectId as string },
          fetchPolicy: 'network-only',
          onCompleted: (data) => {
            const latestQuoationId = data.project?.response?.latestQuotation?.id;
            if (latestQuoationId != latestQuotationBeforeGeneration) {
              navigate(`/quotation/${projectId}/${latestQuoationId}/`);
            }
            setIsFileDownloaded(false);
            setIsLoadingUpdate(false);
            handleClose();
          },
        },
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileDownloaded]);
      
  return (
    <div className='flex flex-col justify-between items-center w-full rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Generate PDF version')}</div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 mt-8 self-start px-8">{t('Select layout')}</h2>
        <SelectLayoutPDF
          isLoadingUpdate={isLoadingUpdate}
          setLayoutType={setLayoutType}
          layoutType={layoutType}
        />
        <h2 className="text-base font-semibold leading-7 text-gray-900 self-start px-8">{t('Edit text')}</h2>
        <div className='w-full px-8 mb-8'>
          <TextArea
            id="body"
            name="body"
            rows={20}
            onChange={(e) => setPdfBodyText((e as ChangeEvent<HTMLTextAreaElement>).target.value)}
            label={''}
            placeholder={''}
            value={pdfBodyText}
            disabled={isLoadingUpdate}
          />
        </div>
        <div className='flex justify-end gap-8 pr-8 pb-8 w-full'>
          <button
            onClick={() => handleClose()}
            type="button"
            className="w-1/5 flex justify-center items-center px-4 py-2 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
          >
            {t('Cancel')}
          </button>
          <button
            disabled={mutationLoading || isLoadingUpdate || !selectedGroupsIds.length}
            onClick={handleCreate}
            type="submit"
            className={classNames('w-1/5 flex justify-center items-center px-4 py-2 rounded text-sm font-medium uppercase text-white bg-cblue-500', !(mutationLoading || isLoadingUpdate || !selectedGroupsIds.length) ? 'hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100' : 'bg-cgray-300')}
          >
            {isLoadingUpdate 
              ? <><IconInnerShadowLeft className="w-5 h-5 mr-4 text-white animate-spin" /> <span>{t('Generating...')}</span></>
              : <div>{t('Generate PDF version')}</div>
            }
          </button>
        </div>
    </div>
  );
}
