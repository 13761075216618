const en = {
  translation: {
    'Log out': 'Log out',
    'Feedback': 'Feedback',
    'Dashboard': 'Dashboard',
    'Project Name': 'Project Name',
    'Only integers are allowed': 'Only integers are allowed',
    'Only Groups are allowed to be pasted at the root level': 'Only Groups are allowed to be pasted at the root level',
    'Notes': 'Notes',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'Project External Id': 'Project External Id',
    'Example: 123456': 'Example: 123456',
    "project was successfully created. You can now add products to it's quotation": "project was successfully created. You can now add products to it's quotation",
    'Project connects customer and quotation data.': 'Project connects customer and quotation data.',
    'Project': 'Project',
    'Select Customer': 'Select Customer',
    'Service Wage': 'Service Wage',
    'Select Service Wage': 'Select Service Wage',
    'Select Sales Wage': 'Select Sales Wage',
    'Sales Wage': 'Sales Wage',
    'Create Group Template': 'Create Group Template',
    'Paste below': 'Paste below',
    'New group was successfully created': 'New group was successfully created',
    'New service was successfully created': 'New service was successfully created',
    'Group Template was successfully created': 'Group Template was successfully created',
    'Group description': 'Group description',
    'Service description': 'Service description',
    'Requried': 'Requried',
    'The maximum number of Project External ID cannot exceed 2.147.483.647': 'The maximum number of Project External ID cannot exceed 2.147.483.647',
    'The number of Project External ID cannot be negative or equal 0': 'The number of Project External ID cannot be negative or equal 0',
    'Project External ID must contain only digits': 'Project External ID must contain only digits',
    'Description': 'Description',
    'Position': 'Position',
    'Qty': 'Qty',
    'Product ID': 'Product ID',
    'Supplier': 'Supplier',
    'Wage': 'Wage',
    'Time': 'Time',
    'Purchase Price per Piece': 'Purchase Price per Piece',
    'PP/Piece': 'PP/Piece',
    'Sales Price per Piece': 'Sales Price per Piece',
    'SP/Piece': 'SP/Piece',
    'Net Sales Price': 'Net Sales Price',
    'Profit per Piece': 'Profit per Piece',
    'Total Sales Price': 'Total Sales Price',
    'Total SP': 'Total SP',
    'Total Profit': 'Total Profit',
    'Datanorm': 'Datanorm',
    'Type': 'Type',
    'GTIN': 'GTIN',
    'Notes': 'Notes',
    'Price': 'Price',
    'Match code': 'Match code',
    'Price indicator': 'Price indicator',
    'Units per price': 'Units per price',
    'Order amount': 'Order amount',
    'Price/Piece': 'Price/Piece',
    'Unit': 'Unit',
    'Group Discount': 'Group Discount',
    'Discount': 'Discount',
    'Discounted Price/Piece': 'Discounted Price/Piece',
    'Raw Material Surcharge': 'Raw Material Surcharge',
    'ETIM': 'ETIM',
    'Short Description': 'Short Description',
    'Long Description': 'Long Description',
    'ETIM Group': 'ETIM Group',
    'ETIM Class': 'ETIM Class',
    'Specific Surcharge': 'Specific Surcharge',
    'Base Sales Price/Piece': 'Base Sales Price/Piece',
    'Material Cost Burden Rate': 'Material Cost Burden Rate',
    'Material Overhead': 'Material Overhead',
    'Material Costs': 'Material Costs',
    'Manufacturing Time': 'Manufacturing Time',
    'Sales Time': 'Sales Time',
    'Manufacturing Labour Cost': 'Manufacturing Labour Cost',
    'Manufacturing Overhead Rate': 'Manufacturing Overhead Rate',
    'Manufacturing Overhead': 'Manufacturing Overhead',
    'Production Cost': 'Production Cost',
    'Manufacturing Cost': 'Manufacturing Cost',
    'Sales Overhead Rate': 'Sales Overhead Rate',
    'Sales Overhead': 'Sales Overhead',
    'Special Direct Costs Sales': 'Special Direct Costs Sales',
    'Shipping Cost': 'Shipping Cost',
    'Sales Cost': 'Sales Cost',
    'Administrative Overhead Rate': 'Administrative Overhead Rate',
    'Administrative Costs': 'Administrative Costs',
    'Cost Administration Sales': 'Cost Administration Sales',
    'Cost Per Item': 'Cost Per Item',
    'costs per unit': 'costs per unit',
    'Profit Surcharge': 'Profit Surcharge',
    'Profit': 'Profit',
    'Discount': 'Discount',
    'Show Suggestions': 'Show Suggestions',
    'Open options': 'Open options',
    'Hide details': 'Hide details',
    'Show details': 'Show details',
    'Create Group': 'Create Group',
    'Create Group Below': 'Create Group Below',
    'Create Group Inside': 'Create Group Inside',
    'Create Service': 'Create Service',
    'Create Service Below': 'Create Service Below',
    'Create Service Inside': 'Create Service Inside',
    'Add Item': 'Add Item',
    'Add Item Below': 'Add Item Below',
    'Add Item Inside': 'Add Item Inside',
    'Copy Item': 'Copy Item',
    'Cut Item': 'Cut Item',
    'Clear selection': 'Clear selection',
    'Remove Item': 'Remove Item',
    'There are no Groups at the moment. Click here to create a Group.': 'There are no Groups at the moment. Click here to create a Group.',
    'Create New Group': 'Create New Group',
    'Create New Service': 'Create New Service',
    'This action will create new group in current Quotation': 'This action will create new group in current Quotation',
    'This action will create new service item in the current Quotation': 'This action will create new service item in the current Quotation',
    'This action will create new service item inside current Group': 'This action will create new service item inside current Group',
    'Create New Quotation': 'Create New Quotation',
    'This Action will create the copy of current Quotation': 'This Action will create the copy of current Quotation',
    'Create': 'Create',
    'Item was successfully deleted': 'Item was successfully deleted',
    'This action will delete': 'This action will delete',
    'item': 'item',
    'Delete': 'Delete',
    'The following group template(s) can be used instead of the current group:': 'The following group template(s) can be used instead of the current group:',
    'Group Templates Suggestions': 'Group Templates Suggestions',
    'Use': 'Use',
    'SubTotal': 'SubTotal',
    'Total': 'Total',
    'Quotation Total': 'Quotation Total',
    'Create copy of current Quotation': 'Create copy of current Quotation',
    'Add Multiple Products': 'Add Multiple Products',
    'Create Group at the Bottom': 'Create Group at the Bottom',
    'Reset Items Positions': 'Reset Items Positions',
    'Generate PDF version': 'Generate PDF version',
    'Quotation': 'Quotation',
    'Project': 'Project',
    'Estimate': 'Estimate',
    'Drag and Drop mode': 'Drag and Drop mode',
    'Release version': 'Release version',
    'Search Class': 'Search Class',
    'Yes': 'Yes',
    'No': 'No',
    'Groups': 'Groups',
    'To activate the Search, please select Group and Сlass': 'To activate the Search, please select Group and Сlass',
    'Search': 'Search',
    'Select records to unblock Paste Mode': 'Select records to unblock Paste Mode',
    'Paste mode': 'Paste mode',
    'Search by': 'Search by',
    'ETIM Standard': 'ETIM Standard',
    'Quotation': 'Quotation',
    'Open options': 'Open options',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
    'Remove': 'Remove',
    'Select': 'Select',
    'No results found': 'No results found',
    'Search results will be displayed here': 'Search results will be displayed here',
    'Please hit Enter to start Search': 'Please hit Enter to start Search',
    'There are no records at the moment.': 'There are no records at the moment.',
    'Drag records here to attach them to this Quotation.': 'Drag records here to attach them to this Quotation.',
    'Add to selected Quotation': 'Add to selected Quotation',
    'Previous': 'Previous',
    'Next': 'Next',
    'Email is Required': 'Email is Required',
    'Password is Required': 'Password is Required',
    'E-mail': 'E-mail',
    'Password': 'Password',
    'Log in': 'Log in',
    'Error: Bad Request.': 'Error: Bad Request.',
    'Error: Unauthorized.': 'Error: Unauthorized.',
    'Error: Forbidden.': 'Error: Forbidden.',
    'Error: Not Found.': 'Error: Not Found.',
    'Internal Server Error.': 'Internal Server Error.',
    'Error: Bad Gateway.': 'Error: Bad Gateway.',
    'Please Select the Quotation Project': 'Please Select the Quotation Project',
    'Create New Project': 'Create New Project',
    'Pages': 'Pages',
    'Menu': 'Menu',
    'User': 'User',
    'quotation': 'quotation',
    'quotations': 'quotations',
    'Represents Group Template': 'Represents Group Template',
    'Features': 'Features',
    'Service Time': 'Service Time',
    'It is forbidden to add services to the root level': 'It is forbidden to add services to the root level',
    'Deselect All': 'Deselect All',
    'Select All': 'Select All',
    'Manufacturing time, per item': 'Manufacturing time, per item',
    'Manufacturing wage': 'Manufacturing wage',
    'Service time, per item': 'Service time, per item',
    'Service time must contain only digits': 'Service time must contain only digits',
    'Sum of nested time values, excluding Sales time': 'Sum of nested time values, excluding Sales time',
    'Required': 'Required',
    'Number must be positive': 'Number must be positive',
    'Selected catalogs': 'Selected catalogs',
    'Shipping cost': 'Shipping cost',
    'obsolete value': 'obsolete value',
    'Service Labour Cost': 'Service Labour Cost',
    'Net Sales Price/Piece': 'Net Sales Price/Piece',
    'Net Sales Price/Piece including discount': 'Net Sales Price/Piece including discount',
    'Special Price': 'Special Price',
    'Select Project Type': 'Select Project Type',
    'Only Groups can be added to the root level': 'Only Groups can be added to the root level',
    'No features selected yet': 'No features selected yet',
    'Not applicable': 'Not applicable',
    'Regenerate suggestion': 'Regenerate suggestion',
    'Suggested Price per Piece': 'Suggested Price per Piece',
    'for': 'for',
    'Product Price Suggestion was updated': 'Product Price Suggestion was updated',
    'Info': 'Info',
    'Icons legend': 'Icons legend',
    'Group Item. Can be placed at the root level of Quotation. Any Item type can be placed inside the Group.': 'Group Item. Can be placed at the root level of Quotation. Any Item type can be placed inside the Group.',
    'This Group represents Group Template.': 'This Group represents Group Template.',
    'This Group has Template presets to apply.': 'This Group has Template presets to apply.',
    'Product item. Represents products from ETIM catalogs. Can only be placed inside Groups.': 'Product item. Represents products from ETIM catalogs. Can only be placed inside Groups.',
    'Service Item. Represents on-site services. Can only be placed inside Groups.': 'Service Item. Represents on-site services. Can only be placed inside Groups.',
    'Items drag handle.': 'Items drag handle.',
    'Show suggestions based on Group Templates stored in database.': 'Show suggestions based on Group Templates stored in database.',
    'Create Group Template from selected Group.': 'Create Group Template from selected Group.',
    'List of all available keyboard shortcuts': 'List of all available keyboard shortcuts',
    'Collapse Group': 'Collapse Group',
    'Uncollapse Group': 'Uncollapse Group',
    'Move down': 'Move down',
    'Move up': 'Move up',
    'Show details': 'Show details',
    'Close details': 'Close details',
    'Create Group Below': 'Create Group Below',
    'Create Group Inside': 'Create Group Inside',
    'Create Service Below': 'Create Service Below',
    'Create Service Inside': 'Create Service Inside',
    'Add Item Below': 'Add Item Below',
    'Add Item Inside': 'Add Item Inside',
    'Copy Item': 'Copy Item',
    'Paste Item': 'Paste Item',
    'Clear selection': 'Clear selection',
    'Delete Item': 'Delete Item',
    'Field was successfully updated': 'Field was successfully updated',
    'Item was successfully pasted': 'Item was successfully pasted',
    'Position should contain only positive numbers and dots': 'Position should contain only positive numbers and dots',
    'Quotation not found': 'Quotation not found',
    'Items positions generation disabled': 'Items positions generation disabled',
    'Street': 'Street',
    'City': 'City',
    'Country': 'Country',
    'Zip code': 'Zip code',
    'Project details:': 'Project details:',
    'Project address:': 'Project address:',
    'Equipment installation address.': 'Equipment installation address.',
    'Zip code must be no more than 6 characters': 'Zip code must be no more than 6 characters',
    'Create New Item': 'Create New Item',
    'Quantity must contain only digits': 'Quantity must contain only digits',
    'New product was successfully created': 'New product was successfully created',
    'quantity': 'quantity',
    'Quantity': 'Quantity',
    'price': 'price',
    'Please fill out all fields first and have a look at available options': 'Please fill out all fields first and have a look at available options',
    'Create New Product': 'Create New Product',
    'Create Product Below': 'Create Product Below',
    'Create Product Inside': 'Create Product Inside',
    'The following item(s) may be used:': 'The following item(s) may be used:',
    'Suggestions will be displayed here': 'Suggestions will be displayed here',
    'Use instead': 'Use instead',    
    'Save': 'Save',
    'Compose mode is disabled for ReadOnly quotations': 'Compose mode is disabled for ReadOnly quotations',
    'Compose': 'Compose',
    'Favorite classes': 'Favorite classes',
    'Default features': 'Default features',
    'Edit Feature': 'Edit Feature',
    'Add Feature': 'Add Feature',
    'Select Class': 'Select Class',
    'Select Feature': 'Select Feature',
    'Select feature for current Group': 'Select feature for current Group',
    'Select Value': 'Select Value',
    'Value': 'Value',
    'Delete Feature': 'Delete Feature',
    'This action will delete feature': 'This action will delete feature',
    'The minimum value must be less or equal to the maximum value': 'The minimum value must be less or equal to the maximum value',
    'Service time': 'Service time',
    'Feature was successfully deleted': 'Feature was successfully deleted',
    'Feature was successfully updated': 'Feature was successfully updated',
    'New feature was added': 'New feature was added',
    'Select item in Quotation': 'Select item in Quotation',
    'Product/Service cannot be pasted on the root level': 'Product/Service cannot be pasted on the root level',
    'Paste to the selected Quotation': 'Paste to the selected Quotation',
    'Products': 'Products',
    'Groups': 'Groups',
    'All': 'All',
    'Search for': 'Search for',
    'Move Group Down': 'Move Group Down',
    'Move Group Up': 'Move Group Up',
    'The maximum number cannot exceed 2.147.483.647': 'The maximum number cannot exceed 2.147.483.647',
    'The number cannot be negative or equal 0': 'The number cannot be negative or equal 0',
    'Days of validity must contain only digits': 'Days of validity must contain only digits',
    'Days of validity': 'Days of validity',
    'All nested groups are selected automatically if parent group is selected': 'All nested groups are selected automatically if parent group is selected',
    'Basic Price and Quantity': 'Basic Price and Quantity',
    'Price and quantity at the Root Level, quantity at Level 1, and description at Level 2': 'Price and quantity at the Root Level, quantity at Level 1, and description at Level 2',
    'Standard Price and Quantity': 'Standard Price and Quantity',
    'Price and quantity at the Root Level and Level 1, quantity at Level 2, and description at Level 3': 'Price and quantity at the Root Level and Level 1, quantity at Level 2, and description at Level 3',
    'Advanced Price and Quantity': 'Advanced Price and Quantity',
    'Price and quantity at the Root Level, Level 1, and Level 2, quantity at Level 3, and description at Level 4': 'Price and quantity at the Root Level, Level 1, and Level 2, quantity at Level 3, and description at Level 4',
    'Comprehensive Price and Quantity': 'Comprehensive Price and Quantity',
    'Price and quantity at the Root Level, Level 1, Level 2, and Level 3, quantity at Level 4, and description at Level 5': 'Price and quantity at the Root Level, Level 1, Level 2, and Level 3, quantity at Level 4, and description at Level 5',
    'Select groups': 'Select groups',
    'Select layout': 'Select layout',
    'Edit text': 'Edit text',
    'Generating...': 'Generating...',
    'Client extension': 'Client extension',
    'Rejected': 'Rejected',
    'Production': 'Production',
    'Draft': 'Draft',
    'Completed': 'Completed',
    'Awaiting approval': 'Awaiting approval',
    'Approved': 'Approved',
    'Add your note here': 'Add your note here',
    'Export as Electronic Sheet': 'Export as Electronic Sheet',
    'Project Status': 'Project Status',
    'Project Log': 'Project Log',
    'Delete this Note?': 'Delete this Note?',
    'Select managers for this project': 'Select managers for this project',
    'Is Production Unit': 'Is Production Unit',
    'Select ETIM Class': 'Select ETIM Class',
    'General': 'General',
    'Address was successfully updated': 'Address was successfully updated',
    'Project was successfully updated': 'Project was successfully updated',
    'Bill of Quantities': 'Bill of Quantities',
    'Direct sale': 'Direct sale',
    'Edit Project Address': 'Edit Project Address',
    'Edit Project Details': 'Edit Project Details',
    'Project managers': 'Project managers',
    'Edit project': 'Edit project',
    'Production Unit Status': 'Production Unit Status',
    'Is Production Unit?': 'Is Production Unit?',
    'No Statuses for Production Units available.': 'No Statuses for Production Units available.',
    'Please contact your administrator.': 'Please contact your administrator.',
    'Can not be changed for readonly quotation': 'Can not be changed for readonly quotation',
    'Production Unit Status can be changed only if Project Status is "Production"': 'Production Unit Status can be changed only if Project Status is "Production"',
    'Disabled for search by Quotation': 'Disabled for search by Quotation',
    'ETIM Catalog': 'ETIM Catalog',
    'Manual': 'Manual',
    'Absolute': 'Absolute',
    'Percentage': 'Percentage',
    'Manual Price': 'Manual Price',
    'Set manual Price?': 'Set manual Price?',
    'Price type': 'Price type',
    'Price Value': 'Price Value',
    'This group (or any of nested groups) has percentage type of manual price. This price is going to be saved as Absolute price.': 'This group (or any of nested groups) has percentage type of manual price. This price is going to be saved as Absolute price.',
    'Looking for Group Template Suggestions': 'Looking for Group Template Suggestions',
    'No Group Template suggestions found': 'No Group Template suggestions found',
    'Group Template suggestions found': 'Group Template suggestions found',
    'Confirm': 'Confirm',
  },
};

export default en;