import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_GROUP_ETIM_FEATURE } from '../../../api/mutations/quotations/group';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../api/queries/quotations/details';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { setSuccessAlert } from '../../../redux/alertSlice';
import { setIsLoadingUpdate } from '../../../redux/quotationSlice';

interface Props {
  handleClose: () => void,
}

export default function DeleteFeatureModal(props: Props) {
  const { handleClose } = props;
  const groupId = useAppSelector(state => state.quotation.activeQuotationGroupId);
  const groupFeatureId = useAppSelector(state => state.quotation.groupFeatureIdToEdit);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteFeatureMutation, { loading, data }] = useMutation(DELETE_GROUP_ETIM_FEATURE, {
    refetchQueries: [{
      query: QUOTATION_GROUP_DETAILS_QUERY,
      variables: {
        group: groupId,
      },
    }],
  });

  const onDelete = () => {
    deleteFeatureMutation({ variables: { groupFeature: groupFeatureId } });
  };

  useEffect(() => {
    if (data && data.deleteGroupFeature && data.deleteGroupFeature.response) {
      dispatch(setSuccessAlert(
        [`${t('Feature was successfully deleted')}`],
      ));
      handleClose();
    }
  }, [dispatch, handleClose, t, data]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(loading));
  }, [dispatch, loading]);

  return (
      <div className='flex flex-col justify-between items-center rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
        <div className='pl-8 mb-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Delete Feature')}</div>
        <div className='w-full pl-8 mb-8'>
        <span>{t('This action will delete feature')}</span>
        </div>
        <div className="mb-8 pr-8 flex justify-end w-full gap-x-8 ">
            <button
              type="button"
              onClick={() => handleClose()}
              className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
            >
              {t('Cancel')}
            </button>
            <button
              type="button"
              onClick={onDelete}
              className="w-1/5 flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
            >
              {t('Delete')}
            </button>
          </div>
      </div>
  );
}
