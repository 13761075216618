import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ProjectAddressType } from '../../__generated__/graphql';
import { UPDATE_PROJECT_ADDRESS_MUTATION } from '../../api/mutations/projects';
import { PROJECT_ADDRESS_QUERY } from '../../api/queries/projects';
import { useAppDispatch } from '../../helpers/reduxHooks';
import { Input } from '../../layout/fields';
import { setSuccessAlert } from '../../redux/alertSlice';

interface FormValues {
  street: string,
  zipCode: string,
  city: string,
  country: string,
}

interface Props {
  projectAddressData: ProjectAddressType
}

export default function EditProjectAddressForm(props: Props) {
  const { projectAddressData } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { projectId } = useParams();

  const [
    projectAddressMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(UPDATE_PROJECT_ADDRESS_MUTATION, {
    refetchQueries: [{
      query: PROJECT_ADDRESS_QUERY,
      variables: {
        project: projectId,
      },
    }],
  });

  const schema = yup.object({
    street: yup.string().trim().required(`${t('Requried')}`),
    zipCode: yup.string().trim().max(6, `${t('Zip code must be no more than 6 characters')}`).required(`${t('Requried')}`),
    city: yup.string().trim().required(`${t('Requried')}`),
    country: yup.string().trim().required(`${t('Requried')}`),
  });

  const initialValues: FormValues = {
    street: projectAddressData.street,
    zipCode: projectAddressData.zipCode,
    city: projectAddressData.city,
    country: projectAddressData.country,
  };

  const {
    handleChange,
    handleSubmit,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {      
      const variables = {
        ...values,
        project: projectId as string,
        id: projectAddressData.id,
      };
      projectAddressMutation({ variables });
    },
  }); 
  
  useEffect(() => {
    if (mutationData && mutationData.updateProjectAddress && mutationData.updateProjectAddress.response) {
      dispatch(setSuccessAlert(
        [t('Address was successfully updated')],
      ));
    }
  }, [mutationData, dispatch, t]);

  return (
    <form className="px-12 py-8" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-12 w-full rounded-b-xl">
        <div className="w-[800px]">
          <h2 className="text-base font-semibold leading-7 text-gray-900">{t('Project address:')}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{t('Equipment installation address.')}</p>
        </div>

        <div className="relative w-full">
          <Input
            id="street"
            name="street"
            type="text"
            value={formikValues.street}
            //@ts-ignore
            errorMessage={touched.street && formikErrors.street}
            label={t('Street')}
            placeholder={t('Street')}
            required
            onChange={handleChange}
          />
        </div>

        <div className="relative mt-2 w-full">
          <Input
            id="city"
            name="city"
            type="text"
            value={formikValues.city}
            //@ts-ignore
            errorMessage={touched.city && formikErrors.city}
            label={t('City')}
            placeholder={t('City')}
            required
            onChange={handleChange}
          />
        </div>

        <div className="relative mt-2 w-full">
          <Input
            id="country"
            name="country"
            type="text"
            value={formikValues.country}
            //@ts-ignore
            errorMessage={touched.country && formikErrors.country}
            label={t('Country')}
            placeholder={t('Country')}
            required
            onChange={handleChange}
          />
        </div>

        <div className="relative mt-2 w-full">
          <Input
            id="zipCode"
            name="zipCode"
            type="text"
            value={formikValues.zipCode}
            //@ts-ignore
            errorMessage={touched.zipCode && formikErrors.zipCode}
            label={t('Zip code')}
            placeholder={t('Zip code')}
            required
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end w-[800px] gap-x-4 ">
        <button
          type="button"
          onClick={() => navigate('/')}
          className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
        >
          {t('Cancel')}
        </button>
        <button
          disabled={mutationLoading}
          type="submit"
          className="w-1/5 flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
        >
          {t('Save')}
        </button>
      </div>
    </form>

  );
}
