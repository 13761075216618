import { useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_BY_DESCRIPTION_QUERY } from '../../../api/queries/searchRecords';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { isNonEmpty } from '../../../helpers/utils';
import { setIsCreateItemOverlayOpen } from '../../../redux/quotationSlice';
import SearchListDescriptionData from '../../search/SearchSuggestions/list/SearchListDescriptionData';
import SearchSuggestionsListWrapper from '../../search/SearchSuggestions/list/SearchSuggestionsListWrapper';
import CreateProductForm from '../list/createForms/CreateProductForm';

function CreateProductOverlay() {
  const isCreateItemOverlayOpen = useAppSelector(state => state.quotation.isCreateItemOverlayOpen);
  const dispatch = useAppDispatch();
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const searchListPage = useAppSelector(state => state.search.searchListPage);
  const createNewItemInside = useAppSelector(state => state.quotation.createNewItemInside);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);

  const { t } = useTranslation();
  
  const {
    data: searchRecordsByDescriptionData,
    loading: searchRecordsByDescriptionLoading,
  } = useQuery(SEARCH_BY_DESCRIPTION_QUERY, {
    variables: {
      page: searchListPage,
      searchInput: searchInputValue as string[],
    },
    skip: !isNonEmpty(searchInputValue) || !searchInputValue[0],
  });

  return (
    <Transition.Root show={isCreateItemOverlayOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={() => dispatch(setIsCreateItemOverlayOpen(false))}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-4/5 inset-y-0 right-0 max-w-full flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsCreateItemOverlayOpen(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex gap-8 flex-col justify-start items-center h-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <div className='shrink-0 pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Create New Product')}</div>
                    <CreateProductForm 
                      parentId={createNewItemInside ? selectedItem?.id : selectedItem?.parentId}
                      searchRecordsByDescriptionLoading={searchRecordsByDescriptionLoading}
                    />
                    
                    <div className='w-full h-full overflow-scroll px-10 flex flex-col'>
                      <SearchListDescriptionData 
                        SearchListWrapper={SearchSuggestionsListWrapper} 
                        searchRecordsByDescriptionData={searchRecordsByDescriptionData}
                        searchRecordsByDescriptionLoading={searchRecordsByDescriptionLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateProductOverlay;
