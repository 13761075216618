import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CREATE_QUOTATION_GROUP_MUTATION } from '../../../../api/mutations/quotations/group';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { Input } from '../../../../layout/fields';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsLoadingUpdate } from '../../../../redux/quotationSlice';

interface FormValues {
  description: string,
}

interface GroupMutationVariables {
  description: string;
  quotation: string;
  order: number;
  parent?: string; 
}

interface Props {
  handleClose: () => void,
  targetOrder?: number,
  parentId?: ID | null,
}

export default function CreateGroupForm(props: Props) {
  const { handleClose, targetOrder, parentId } = props;
  const dispatch = useAppDispatch();
  const { quotationId } = useParams();
  const { t } = useTranslation();

  const [
    groupMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(CREATE_QUOTATION_GROUP_MUTATION, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
      fetchPolicy: 'no-cache',
    }],
  });

  const schema = yup.object({
    description: yup.string().trim().required(`${t('Requried')}`),
  });

  const initialValues: FormValues = {
    description: '',
  };

  const {
    handleChange,
    handleSubmit,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables: GroupMutationVariables = {
        ...values,
        description: values.description,
        quotation: quotationId as string,
        order: targetOrder !== undefined ? targetOrder : 0,
      };
      if (parentId) {
        variables.parent = parentId as string;
      }
      groupMutation(
        {
          variables,
          onError: () => {
            handleClose();
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
    },
  });

  useEffect(() => {
    if (mutationData && mutationData.createQuotationGroup && mutationData.createQuotationGroup.response) {
      dispatch(setSuccessAlert(
        [`${t('New group was successfully created')}`],
      ));
      handleClose();
    }
  }, [mutationData, dispatch, quotationId, handleClose, t]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);


  return (
    <form className="px-8 w-full" onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-8 w-full">
          <div className="flex flex-col gap-8 w-full">
            <div className="relative mt-2">
              <Input
                id="description"
                name="description"
                type="text"
                value={formikValues.description}
                //@ts-ignore
                errorMessage={touched.description && formikErrors.description}
                label={t('Group description')}
                placeholder={t('Group description')}
                required
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mt-4 flex items-center justify-end w-full gap-x-8 ">
            <button
              type="button"
              onClick={() => handleClose()}
              className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
            >
              {t('Cancel')}
            </button>
            <button
              disabled={mutationLoading}
              type="submit"
              className="w-1/5 flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
