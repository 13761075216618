/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from '@apollo/client';
import { Menu, Popover, Transition } from '@headlessui/react';
import {
  IconMenu2,
  IconX,
  IconExternalLink,
} from '@tabler/icons-react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { REVOKE_REFRESH_TOKEN_MUTATION } from '../api/mutations/users';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { clearAuthCookies, getRefreshToken } from '../helpers/auth';
import { classNames } from '../helpers/utils';

function UserNavigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [revokeRefreshToken,
    { data: revokeRefreshTokenData },
  ] = useMutation(REVOKE_REFRESH_TOKEN_MUTATION);

  const handleLogout = () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      revokeRefreshToken({ variables: { refreshToken: refreshToken } });
    }
  };

  useEffect(() => {
    if (revokeRefreshTokenData) {
      clearAuthCookies();
      navigate('/authentication/login/');
    }
  }, [revokeRefreshTokenData, navigate]);

  return (
    <div
      onClick={() => {
        handleLogout();
      }}
      role="button"
      tabIndex={0}
      className="block px-4 py-2 text-base font-medium  hover:bg-cgray-100"
    >
      {t('Log out')}
    </div>
  );
}

function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigationLinks = [
    {
      category: `${t('Pages')}`,
      links: [
        { name: `${t('Dashboard')}`, path: '/projects/' },
        { name: `${t('Search')}`, path: '/search/multiple/' },
        { name: `${t('Create New Project')}`, path: '/project/create/' },
      ],
    },
  ];
  const { data: userData, error: userError } = useQuery(CURRENT_USER_QUERY);
  // checks that we can do API call to backend, means
  // JWT token is in place and it is valid.
  if (userError) {
    clearAuthCookies();
    navigate('/authentication/login/');
  }

  const user = userData && userData.user;

  const userAvatarLetters = (user?.firstName && user?.lastName) 
    ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
    : user?.email.slice(0, 2).toUpperCase();

  if (user) {
    return (
      <Popover as="nav" className="bg-cblue-500">
        {({ open: openNavbar }) => (
          <div className="mx-auto">
            <div className="relative flex justify-between h-14 px-4">
              {/* Navigation Menu bar */}
              <div className="flex">
                <Popover.Button className="flex-shrink-0 flex items-center text-white">
                  <IconMenu2 className="block h-6 w-6" aria-hidden="true" />
                  <div className="pl-4">{t('Menu')}</div>
                </Popover.Button>
              </div>
              <Transition
                show={openNavbar}
                as={Fragment}
                enter="transform transition ease-in-out duration-200"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Popover.Panel className="absolute top-0 left-0 z-40 flex flex-col gap-4 h-screen w-full max-w-sm bg-cblue-500 text-white shadow shadow-cgray-800">
                  <div className="flex pl-4">
                    <Popover.Button className="h-14 flex-shrink-0 flex items-center text-white">
                      <IconX className="block h-6 w-6 z-10" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <div className="pl-9 pt-14 absolute flex flex-col gap-4 h-screen w-full max-w-sm bg-cblue-500 text-white shadow-lg shadow-cgray-800">
                    {navigationLinks.map((item, categoryIndex) => (
                      <div key={categoryIndex} className="flex flex-col gap-4">
                        <div className="font-semibold text-lg">{item.category}</div>
                        <div className="pl-6 flex flex-col gap-1">
                          {item.links.map((link, linkIndex) => (
                            <Link
                              key={linkIndex}
                              to={link.path}
                            >
                                {link.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* Additional Navigation options for mobile layout */}
                    <div className="sm:hidden">
                      <div className="font-semibold text-lg">{t('User')}</div>
                      <div className="pl-2 flex flex-col gap-1">
                        <UserNavigation />
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>

              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <a
                  target='_blank'
                  href='https://forms.clickup.com/24398038/f/q8j6p-32752/R2P0MQK4BCDDL3KBO8'
                  className=" py-2 px-2 flex items-center gap-2 leading-none text-sm text-white border rounded border-cblue-500 bg-white/10 hover:bg-white/20 transition duration-150"
                >
                  <IconExternalLink className='h-5 w-5 cursor-pointer' />
                  <span>{t('Feedback')}</span>
                </a>
                {/* Profile dropdown */}
                <Menu as="div" className="z-40 ml-5 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className={classNames(open ? 'outline-none ring-2 ring-offset-2 ring-cblue-500' : '', 'h-8 w-8 bg-white flex items-center justify-center text-sm rounded-full')}>
                          {userAvatarLetters}
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <UserNavigation />
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        )}
      </Popover>
    );
  }
  return <></>;
}

export default Navbar;
