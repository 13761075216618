import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from, fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';
import { REFRESH_JWT_MUTATION } from './api/mutations/users';
import App from './app';
import './assets/css/index.css';
import favicon from './assets/images/favicon.ico';
import config from './config';
import { REFRESH_TOKEN_EXPIRED_ERROR, SIGNATURE_EXPIRED_ERROR } from './constants';
import { clearAuthCookies, setAuthCookies } from './helpers/auth';
import { discardAlert, setErrorAlert } from './redux/alertSlice';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './i18n';



let isRefreshing = false;
let pendingRequests: [] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: () => void) => callback());
  pendingRequests = [];
};

const httpLink = new HttpLink({ uri: config.API_URL });

const errorLink = onError(
  // @ts-ignore
  ({
    graphQLErrors, operation, forward,
  }) => {
    if (graphQLErrors) {
      let forward$;

      for (const err of graphQLErrors) {
        switch (err.message) {
          case SIGNATURE_EXPIRED_ERROR:

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                client
                  .mutate({
                    mutation: REFRESH_JWT_MUTATION,
                    variables: { refreshToken: Cookies.get('jwt_refresh') as string },
                  })
                  .then(({ data }) => {
                    if (data && data.refreshToken) {
                      setAuthCookies(data.refreshToken.token, data.refreshToken.refreshToken);
                      return true;
                    }
                  })

                  // eslint-disable-next-line @typescript-eslint/no-loop-func
                  .catch((error) => {
                    pendingRequests = [];
                    // Clear cookies and forward to login screen.
                    if (error.message === REFRESH_TOKEN_EXPIRED_ERROR) {
                      // eslint-disable-next-line no-use-before-define
                      clearAuthCookies();
                      window.location.href = '/authentication/login';
                    }
                    return false;
                  })
                  // eslint-disable-next-line @typescript-eslint/no-loop-func
                  .finally(() => {
                    isRefreshing = false;
                    resolvePendingRequests();
                  }),
              );
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                new Promise((resolve) => {
                  // @ts-ignore
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));

          default:
            store.dispatch(setErrorAlert([err.message]));
        }
        return null;
      }
    }
  },
);

const customErrorLink = new ApolloLink((operation: any, forward) => {
  return forward(operation).map((response) => {
    const responseData = response.data;
    if (responseData) {
      const key = Object.keys(responseData)[0];
      const innerData = responseData[key];
      if (innerData && innerData.errors && innerData.errors.length) {
        const errList: string[] = [];
        innerData.errors.forEach((error: any) => {
          error.messages.forEach((message: string) => {
            errList.push(`${message}`);
          });
        });
        store.dispatch(setErrorAlert(errList));
      } else if (operation.query.definitions[0]?.operation === 'mutation') {
        store.dispatch(discardAlert());
      }
    }
    return response;
  });
});

const authLink = setContext(async (_, { headers }) => {
  const token = Cookies.get('jwt_token');

  if (!isRefreshing && token) {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${token}`,
      },
    };
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get('jwt_token');
  if (!token && !window.location.toString().includes('authentication')) {
    window.location.href = '/authentication/login';
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authMiddleware, errorLink, customErrorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          notes: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

// @ts-ignore
const root = createRoot(document.getElementById('root'));
root.render(
  // @ts-ignore
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Favicon url={favicon} />
      <App />
    </ApolloProvider>
  </Provider>,
);
serviceWorker.unregister();
