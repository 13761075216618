import { useApolloClient, useLazyQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INLINE_PROJECTS_QUERY, PROJECT_QUERY } from '../../../api/queries/projects';
import { INLINE_RECORDS_SIZE } from '../../../config';
import { SearchResultsType, SearchType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../../helpers/utils';
import { SearchInput, SelectInput } from '../../../layout/fields';
import SelectEtimCatalog from '../../../layout/fields/SelectEtimCatalog';
import {
  setSearchInputValue,
  setSearchProjectId,
  setSearchQuotationId,
  setSearchResultsType,
  unsetPinnedItems,
} from '../../../redux/searchSlice';
import SelectProject from '../../quotation/topBar/SelectProject';
import SelectQuotation from '../../quotation/topBar/SelectQuotation';

export default function SearchHeader() {
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const { t } = useTranslation();

  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const searchType = useAppSelector(state => state.search.searchType);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const pinnedItems = useAppSelector(state => state.search.pinnedItems);
  const searchResultsType = useAppSelector(state => state.search.searchResultsType);
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const query: DocumentNode = INLINE_PROJECTS_QUERY;

  const [getProject, { data: projectData }] = useLazyQuery(PROJECT_QUERY);

  const handleSearchInputValue = (inputedValues: SelectOption[]) => {
    const searchableValues = inputedValues.map(item => item.value);
    dispatch(setSearchInputValue(searchableValues as string[]));
  };

  const handleProjectChange = (selectedId: string) => {
    dispatch(setSearchProjectId(''));
    setSelectedProjectId(selectedId);
    
    if (searchType === SearchType.QUOTATION) {
      const pinnedItemsIds = pinnedItems.map(item => item.id);
      dispatch(unsetPinnedItems(pinnedItemsIds));
    }
  };

  useEffect(() => {
    if (QuotationContext && QuotationContext.searchProjectId === selectedProjectId) {
      // Do nothing if QuotationContext is not initialized
      // or this value is already set.
      return;
    }

    if (selectedProjectId) {
      getProject({ 
        variables: { project: selectedProjectId },
      });
    }
    const project = projectData?.project?.response;

    if (project && !!project.quotations?.length) {
      dispatch(setSearchProjectId(project.id));
      const latestQuotation = project.quotations[0];
      dispatch(setSearchQuotationId(latestQuotation?.id));
    } else if (project) {
      dispatch(setSearchProjectId(project.id));
      dispatch(setSearchQuotationId(undefined));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, selectedProjectId, QuotationContext]);

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadProjectOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_PROJECTS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  const handleQuotationChange = (selectedId: string) => {
    dispatch(setSearchQuotationId(selectedId));

    if (searchType === SearchType.QUOTATION) {
      const pinnedItemsIds = pinnedItems.map(item => item.id);
      dispatch(unsetPinnedItems(pinnedItemsIds));
    }
  };

  let searchValue: SelectOption[] | undefined;
  if (searchInputValue) {
    searchValue = searchInputValue.map(item => (
      { value: item, label: item }
    ));
  }
  
  const options: SelectOption[] = [
    { value: SearchResultsType.All, label: t('All') },
    { value: SearchResultsType.PRODUCTS, label: t('Products') },
    { value: SearchResultsType.GROUPS, label: t('Groups') },
  ];

  return (
    <div className="bg-white w-full sticky top-0 z-30 pt-4 flex flex-col items-center gap-4 justify-between">
      <div className='flex items-start gap-4 w-full pl-2 pr-4'>
        <Tippy content={t('Disabled for search by Quotation')} disabled={!QuotationContext?.searchProjectId}>
          <div className="flex flex-col w-1/12 min-w-[100px]">
              <SelectInput
                options={options}
                placeholder={t('Search for')}
                value={searchResultsType || options[0]}
                onChange={(e: SelectOption) => dispatch(setSearchResultsType(e))}
                isDisabled={!!QuotationContext?.searchProjectId}
              />
          </div>
        </Tippy>
        <div className="flex flex-col w-3/12">
          <SelectEtimCatalog />
        </div>
        <div className="flex flex-col w-2/12">
          <SelectProject
            onChange={handleProjectChange}
            projectId={QuotationContext && QuotationContext.searchProjectId}
            loadOptions={loadProjectOptions}
            isClearable
          />
        </div>
        {
          QuotationContext && QuotationContext.searchProjectId &&
          <div className="flex flex-col w-2/12">
            <SelectQuotation
              onChange={handleQuotationChange}
              projectId={QuotationContext.searchProjectId}
              quotationId={QuotationContext.searchQuotationId}
            />
          </div>
        }
        <div className='w-4/12 grow'>
          <SearchInput
            placeholder={t('Search')}
            isMulti
            onInput={handleSearchInputValue}
            value ={searchValue}
            isDisabled={false}
          />
        </div>
      </div>
    </div>
  );
}
