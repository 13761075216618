import { useQuery } from '@apollo/client';
import { ElementType } from 'react';
import {
  SearchResponseObjectType,
  SearchResponsePageType,
} from '../../__generated__/graphql';
import { SEARCH_BY_DESCRIPTION_QUERY } from '../../api/queries/searchRecords';
import { useDebounceArray } from '../../helpers/customHooks';
import { useAppSelector } from '../../helpers/reduxHooks';
import { isNonEmpty } from '../../helpers/utils';
import { PaginationData, createPaginationData } from './../../helpers/PaginationUtils';

interface Props {
  SearchListWrapper: ElementType,
}

export default function SearchListDescriptionData(props: Props) {
  const { SearchListWrapper } = props;
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchListPage = useAppSelector(state => state.search.searchListPage);

  const {
    data: searchRecordsByDescriptionData,
    loading: searchRecordsByDescriptionLoading,
  } = useQuery(SEARCH_BY_DESCRIPTION_QUERY, {
    variables: {
      page: searchListPage,
      searchInput: debouncedSearchInput as string[],
    },
    skip: !isNonEmpty(debouncedSearchInput),
  });

  let pageData: SearchResponsePageType | undefined = undefined;
  
  if (searchRecordsByDescriptionData
    && searchRecordsByDescriptionData.searchRecordsByDescription
    && searchRecordsByDescriptionData.searchRecordsByDescription.response
  ) {
    pageData = searchRecordsByDescriptionData.searchRecordsByDescription.response as SearchResponsePageType;
  }

  return (
    <SearchListWrapper
      objectsData={pageData?.objects as SearchResponseObjectType[]}
      isLoading={searchRecordsByDescriptionLoading}
      paginationData={createPaginationData(pageData as PaginationData)}
      totalObjectsQuantity={pageData?.totalObjectsQuantity as number}
    />
  );
}
