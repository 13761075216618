import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { EtimReleasesEtimFeatureFeatureTypeChoices, QuotationGroupFeatureType } from '../../../../../../../__generated__/graphql';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import { getLogicalLabel } from '../../../../../../../helpers/utils';
import {
  setShowSelectFeatureModal, setSelectedGroupFeature,
  setActiveQuotationGroupId,
  setShowDeleteFeatureModal,
  setGroupFeatureIdToEdit,
} from '../../../../../../../redux/quotationSlice';

interface Props {
  item: QuotationGroupFeatureType,
  groupId: string,
}

export default function EtimGroupFeature(props: Props) {
  const { item, groupId } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setSelectedGroupFeature({
      feature: item.etimFeature,
      value: item.value,
      id: item.id,
    }));
    dispatch(setActiveQuotationGroupId(groupId));
    dispatch(setShowSelectFeatureModal(true));
  };

  const featureValue = item.etimFeature.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Logical
    ? t(getLogicalLabel(item.value) as string)
    : item.etimFeature.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Range 
      ? `${item.value[0]} - ${item.value[1]}`
      : getLogicalLabel(item.value);
  return (
    <div
      className="pl-2 py-px flex items-center min-w-max max-h-[40px] border border-cgray-200 rounded-t bg-cgray-100 relative group cursor-pointer hover:bg-cgray-200"
      onClick={handleClick}
    >
      <div className='mr-2'>
        <p className='text-cgray-400 text-2xs'>{item.etimFeature.localizedDescription}: </p>
        <p>
          <span>
          {featureValue}
          </span>
        </p>
      </div>
      <div className='w-0 border-cgray-300 border-r flex items-center justify-center h-10'></div>
      <div 
        className='h-full w-8 flex items-center justify-center group'
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setActiveQuotationGroupId(groupId));
          dispatch(setGroupFeatureIdToEdit(item.id));
          dispatch(setShowDeleteFeatureModal(true));
        }}
      
      >
        <IconX
          className='h-5 w-5 cursor-pointer group-hover:text-cred-500'
        />
      </div>
    </div>
  );
}
