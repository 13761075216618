import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FeaturePresetItemType,
  SearchFeature,
  SearchFeatureType,
} from '../../../../__generated__/graphql';
import { SAVE_ETIM_FEATURES_PRESET } from '../../../../api/mutations/etimReleases';
import { FEATURES_PRESET_QUERY } from '../../../../api/queries/etimReleases';
import { CURRENT_USER_QUERY } from '../../../../api/queries/users';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import LoadingIndicator from '../../../../layout/LoadingIndicator';
import { Feature, unsetAllFeatures } from '../../../../redux/searchSlice';
import FeatureDisplay from './FeatureDisplay';

interface Props {
  isEditable?: boolean;
  features?: SearchFeatureType[];
  isLoading?: boolean;
}


function convertPresetToSelectedFeatures(preset: FeaturePresetItemType[]) {
  return preset.map( presetItem => ({
    id: presetItem?.etimFeature?.id,
    userValue: presetItem?.value,
    code: presetItem?.etimFeature?.code as string,
    featureType: presetItem?.etimFeature?.featureType as string,
  }));
}

function areValuesEqual(value1: any, value2: any): boolean {
  // Check if both values are arrays
  if (Array.isArray(value1) && Array.isArray(value2)) {
    // If both are arrays, they must have the same length
    if (value1.length !== value2.length) {
      return false;
    }
    // Compare each element in the arrays
    for (let i = 0; i < value1.length; i++) {
      if (!areValuesEqual(value1[i], value2[i])) {
        return false;
      }
    }
    return true;
  }
  // If not arrays, just compare the values directly
  return value1 === value2;
}

export function areSelectedFeaturesEqual(arr1: Feature[], arr2: Feature[]): boolean {
  // Sort both arrays by the 'id' property
  const sortedArr1 = [...arr1].sort((a, b) => a.id.localeCompare(b.id));
  const sortedArr2 = [...arr2].sort((a, b) => a.id.localeCompare(b.id));

  // Check if arrays are of the same length
  if (sortedArr1.length !== sortedArr2.length) {
    return false;
  }

  // Compare each item in the arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    const item1 = sortedArr1[i];
    const item2 = sortedArr2[i];

    // Use the areValuesEqual function to compare userValue which can handle different types
    if (
      !areValuesEqual(item1.userValue, item2.userValue) ||
      item1.code !== item2.code ||
      item1.featureType !== item2.featureType
    ) {
      return false;
    }
  }

  // If we haven't returned false yet, the arrays are the same
  return true;
}


export default function FeaturesFilter(props: Props) {
  const { isEditable, features, isLoading } = props;
  const [presetRepresentSelectedFeatures, setPresetRepresentSelectedFeatures] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedETIMClassId = useAppSelector(state => state.search.selectedETIMClassId);

  let searchFeatures: SearchFeature[] = [];
  if (selectedFeatures.length) {
    searchFeatures = selectedFeatures.map(feature => {
      return { code: feature.code, featureType: feature.featureType, userValue: feature.userValue };
    });
  }

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const {
    data: presetData,
  } = useQuery(FEATURES_PRESET_QUERY, {
    variables: {
      etimClass: selectedETIMClassId,
    },
    skip: !selectedETIMClassId,
  });

  const [
    saveEtimFeaturesPreset,
    {
      loading: saveEtimFeaturesPresetLoading,
    },
  ] = useMutation(SAVE_ETIM_FEATURES_PRESET, {
    refetchQueries: [
      {
        query: FEATURES_PRESET_QUERY,
        variables: {
          etimClass: selectedETIMClassId,
        },
      },
    ],
  },
  );

  const handlePresetSave = () => {
    saveEtimFeaturesPreset(
      {
        variables: {
          etimClass: selectedETIMClassId,
          searchFeatures,
        },
      },
    );
  };


  // Sort features by description
  const sortedFeaturesByDescription = features && [...features].sort((a, b) => {
    const descriptionA = a?.localizedDescription || '';
    const descriptionB = b?.localizedDescription || '';
    return descriptionA.localeCompare(descriptionB);
  });
  
  // Sort selected features to be on top but do not mutate data stored in redux state
  // instead may a copy before sorting data.
  const sortedFeatures = sortedFeaturesByDescription && [...sortedFeaturesByDescription]?.sort((a, b) => {
    const isSelectedFeatureA = selectedFeatures.some(feature => feature.id === a?.id);
    const isSelectedFeatureB = selectedFeatures.some(feature => feature.id === b?.id);
    if (isSelectedFeatureA && !isSelectedFeatureB) {
      return -1;
    } else if (!isSelectedFeatureA && isSelectedFeatureB) {
      return 1;
    } else {
      return 0;
    }
  });

  const featuresSet = !!selectedFeatures?.length;

  function handleCheckboxChange() {
    dispatch(unsetAllFeatures());
  }
  
  const user = userData && userData.user;

  useEffect(() => {
    if (presetData && presetData.featuresPreset && presetData.featuresPreset.response) {
      const convertedPreset = convertPresetToSelectedFeatures(
        presetData.featuresPreset.response as FeaturePresetItemType[]);
      setPresetRepresentSelectedFeatures(
        areSelectedFeaturesEqual(
          convertedPreset, selectedFeatures),
      );
      
    }
  }, [presetData, selectedFeatures]);

  return (
    <>
      {isLoading && (
        <LoadingIndicator className="w-72 flex justify-center items-center border-r-2 border-cgray-300 h-screen pb-52" />
      )}

      {!!features?.length && (
        <div className="grow w-72 flex flex-col gap-5 border-r-2 border-cgray-300 pt-2 pb-16 max-h-screen overflow-y-scroll">
          {isEditable && <h1 className="font-bold text-cblue-500">{t('Features')}</h1>}
          {user?.isSuperuser && isEditable && (
            <div className='flex w-full justify-center pl-2 pr-4 border border-transparent rounded text-base font-medium uppercase text-white'>
            <button
              onClick={handlePresetSave}
              disabled={presetRepresentSelectedFeatures}
              className={classNames(presetRepresentSelectedFeatures 
                ? 'bg-cgray-300 text-cgray-300' 
                : 'bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100', 
              'relative flex w-full justify-center py-2 px-4 border border-transparent rounded text-base font-medium uppercase text-white')}
            >
              {!presetRepresentSelectedFeatures && saveEtimFeaturesPresetLoading 
                ? <div className='w-6 h-6'><LoadingIndicator color="white" className="flex justify-center items-center absolute w-full h-full top-0 right-0" /></div>
                : t('Save')
              }
            </button>
            </div>
          )}
          <div className="relative ml-2">
            {featuresSet
              ? (
              <>
                <input
                  type="checkbox"
                  className="absolute left-0 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cblue-500 focus:cblue-500"
                  checked={featuresSet}
                  onChange={handleCheckboxChange}
                />
                <div className='pl-6 text-xs'>{t('Deselect All')}</div>
              </>
              )
              : (
                <div className='pl-6 text-xs'>{t('No features selected yet')}</div>
              )
            }
            
          </div>
          <div className="features-container flex flex-col gap-5 pr-4">

              {sortedFeatures?.map((feature) => (
                <div 
                  key={feature?.id} 
                  className='flex gap-4 w-full'
                >
                  <FeatureDisplay feature={feature as SearchFeatureType} />
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
