import { UniqueIdentifier } from '@dnd-kit/core/dist/types';
import { useEffect, useMemo, useState } from 'react';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import {
  flattenTree,
  removeChildrenOf,
} from '../../../../helpers/dnd/utilities';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { handleQuotationGroupCollapse } from '../../../../helpers/utils';
import { setMaximumSearchUncollapsedDepth } from '../../../../redux/searchSlice';
import type { FlattenedItem } from '../../../shared/dnd/types';
import GroupItem from './row/group/GroupItem';
import ProductItem from './row/product/ProductItem';
import ServiceItem from './row/service/ServiceItem';

interface Props {
  defaultItems: FlattenedItem[];
  indentationWidth: number;
  isSearchResult: boolean;
}

export default function SearchList(props: Props) {
  const {
    indentationWidth, defaultItems, isSearchResult,
  } = props;
  const [items, setItems] = useState(defaultItems);
  const dispatch = useAppDispatch();

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<any>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );
    return removeChildrenOf(
      flattenedTree,
      collapsedItems,
    );
  }, [items]);

  useEffect(() => {
    const deepestSearchUncollapsedLevel = Math.max(...flattenedItems.map(item => item.depth));
    dispatch(setMaximumSearchUncollapsedDepth(deepestSearchUncollapsedLevel));
  }, [flattenedItems, dispatch]);

  useEffect(() => {
    // Update local items each time we have updated items incoming outside this component.
    setItems(defaultItems);
  }, [defaultItems]);


  function handleCollapse(idsToProcess: UniqueIdentifier[]) {
    setItems((itemsToUpdate) =>
      handleQuotationGroupCollapse(itemsToUpdate, idsToProcess),
    );
  }

  return (
    <>
      {flattenedItems.map((item) => (
        <div key={item.id}>
          {item.itemType === ItemTypeChoices.Product && (
            <ProductItem
              indentationWidth={indentationWidth}
              isSearchResult={isSearchResult}
              item={item}
            />
          )}
          {item.itemType === ItemTypeChoices.Group && (
            <GroupItem
              collapsed={Boolean(item.collapsed && item.children.length)}
              indentationWidth={indentationWidth}
              item={item}
              onCollapse={
                item.children.length
                  ? () => handleCollapse([item.id])
                  : undefined
              }
            />
          )}
          {item.itemType === ItemTypeChoices.Service && (
            <ServiceItem
              indentationWidth={indentationWidth}
              isSearchResult={isSearchResult}
              item={item}
            />
          )}
        </div>
      ))}
    </>
  );
}
