import { useQuery } from '@apollo/client';
import { InputMaybe, SearchFeature } from '../../__generated__/graphql';
import { SEARCH_UNIVERSAL_QUERY } from '../../api/queries/searchRecords';
import { useDebounceArray } from '../../helpers/customHooks';
import { useAppSelector } from '../../helpers/reduxHooks';
import FilterFeatures from './filters/featuresFilter/FeaturesFilter';
import FilterScope from './filters/FilterScope';


export default function Filter() {
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedETIMClassId = useAppSelector(state => state.search.selectedETIMClassId);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchListPage = useAppSelector(state => state.search.searchListPage);
  const searchResultsType = useAppSelector(state => state.search.searchResultsType);

  let searchFeatures: SearchFeature[] = [];
  if (selectedFeatures.length) {
    searchFeatures = selectedFeatures.map(feature => {
      return { code: feature.code, featureType: feature.featureType, userValue: feature.userValue };
    });
  }

  const {
    data: searchRecordsData,
    loading: searchRecordsLoading,
  } = useQuery(SEARCH_UNIVERSAL_QUERY, {
    variables: {
      page: searchListPage,
      etimClass: selectedETIMClassId,
      searchInput: debouncedSearchInput as InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
      searchFeatures: searchFeatures,
      searchForType: searchResultsType?.value as string | undefined,
    },
    skip: !selectedETIMClassId,
  });

  //@ts-ignore
  const features: SearchFeatureType[] | undefined = searchRecordsData?.[Object.keys(searchRecordsData)[0]]?.response?.features;

  return (
    <div className="pt-2 flex gap-4">
      <FilterScope />
      <FilterFeatures
        isEditable={true}
        isLoading={searchRecordsLoading}
        features={features}
      />
    </div>
  );
}
