import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CREATE_QUOTATION_SERVICE } from '../../../../api/mutations/quotations/service';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { Input } from '../../../../layout/fields';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsLoadingUpdate } from '../../../../redux/quotationSlice';
import SelectServiceWageInput from './SelectServiceWageInput';

interface FormValues {
  description: string,
  serviceWage: SelectOption | undefined,
  serviceTime: number | undefined,
}

interface ServiceMutationVariables {
  description: string;
  quotation: string;
  order: number;
  parent: string; 
  serviceWage: string,
  serviceTime: number;
}

interface Props {
  handleClose: () => void,
  targetOrder?: number,
  parentId?: ID | null,
}

export default function CreateServiceForm(props: Props) {
  const { handleClose, targetOrder, parentId } = props;
  const dispatch = useAppDispatch();
  const { quotationId } = useParams();
  const { t } = useTranslation();

  const [
    createServiceMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(CREATE_QUOTATION_SERVICE, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
      fetchPolicy: 'no-cache',
    }],
  });

  const schema = yup.object({
    description: yup.string().trim().required(`${t('Requried')}`),
    serviceWage: yup.object().required(`${t('Requried')}`),
    serviceTime: yup.number().positive(t('Number must be positive')).integer(`${t('Service time must contain only digits')}`).required(`${t('Requried')}`),
  });

  const initialValues: FormValues = {
    description: '',
    serviceWage: undefined,
    serviceTime: undefined,
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables: ServiceMutationVariables = {
        ...values,
        description: values.description,
        quotation: quotationId as string,
        order: targetOrder !== undefined ? targetOrder : 0,
        serviceWage: values.serviceWage?.value as string,
        parent: parentId as string,
        serviceTime: values.serviceTime as number,
      };
      createServiceMutation(
        {
          variables,
          onError: () => {
            handleClose();
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
    },
  });

  useEffect(() => {
    if (mutationData && mutationData.createQuotationService && mutationData.createQuotationService.response) {
      dispatch(setSuccessAlert(
        [`${t('New service was successfully created')}`],
      ));
      handleClose();
    }
  }, [mutationData, dispatch, quotationId, handleClose, t]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);

  return (
    <form className="px-8 w-full" onSubmit={handleSubmit}>
      <div className="space-y-8">
        <div className="mt-4 w-full">
          <SelectServiceWageInput setFieldValue={setFieldValue} />
          {touched.serviceWage && !!formikErrors.serviceWage && (<div className="mt-2 text-sm text-red-600">{formikErrors.serviceWage}</div>)}
        </div>

        <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-8 w-full">
          <div className="flex flex-col gap-8 w-full">
            <div className="relative mt-2">
              <Input
                id="description"
                name="description"
                type="text"
                value={formikValues.description}
                //@ts-ignore
                errorMessage={touched.description && formikErrors.description}
                label={t('Service description')}
                placeholder={t('Service description')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="serviceTime"
                name="serviceTime"
                type="number"
                defaultValue={formikValues.serviceTime}
                //@ts-ignore
                errorMessage={touched.serviceTime && formikErrors.serviceTime}
                label={t('Service time')}
                placeholder={t('Service time')}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mt-4 flex items-center justify-end w-full gap-x-8 ">
            <button
              type="button"
              onClick={() => handleClose()}
              className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
            >
              {t('Cancel')}
            </button>
            <button
              disabled={mutationLoading}
              type="submit"
              className="w-1/5 flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
