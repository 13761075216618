import {
  useApolloClient, useMutation,
} from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EtimReleasesEtimFeatureFeatureTypeChoices, FeatureType } from '../../../../../../../__generated__/graphql';
import { GROUP_ETIM_FEATURE } from '../../../../../../../api/mutations/quotations/group';
import { INLINE_ALL_FEATURES_QUERY } from '../../../../../../../api/queries/etimReleases';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../../api/queries/quotations/details';
import { INLINE_RECORDS_SIZE } from '../../../../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import { classNames, getInlineOptionsFromQuery } from '../../../../../../../helpers/utils';
import { AsyncSelectInput } from '../../../../../../../layout/fields';
import { setSuccessAlert } from '../../../../../../../redux/alertSlice';
import { 
  setFeatureEdit,
  setIsLoadingUpdate,
  setSelectedGroupFeature,
} from '../../../../../../../redux/quotationSlice';
import GroupFeatureANValue from './GroupFeatureANValue';
import GroupFeatureLogicalValue from './GroupFeatureLogicalValue';
import GroupFeatureNumericValue from './GroupFeatureNumericValue';
import GroupFeatureRangeValue from './GroupFeatureRangeValue';

interface Props {
  handleClose: () => void,
}

export default function SelectFeatureForm(props: Props) {
  const { handleClose } = props;
  const [allFeatures, setAllFeatures] = useState<FeatureType[]>([]);
  const { t } = useTranslation();
  const activeQuotationGroupId = useAppSelector(state => state.quotation.activeQuotationGroupId);
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();
  
  const client = useApolloClient();
  const query: DocumentNode = INLINE_ALL_FEATURES_QUERY;

  const [addFeatureMutation, {
    loading: mutationLoading,
    data: mutationData,
  }] = useMutation(GROUP_ETIM_FEATURE,
    {
      refetchQueries: [
        {
          query: QUOTATION_GROUP_DETAILS_QUERY,
          variables: {
            group: activeQuotationGroupId,
          },
        },
      ],
    });

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadFeaturesOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_ALL_FEATURES_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data, 'localizedDescription');

    setAllFeatures([...allFeatures, ...innerData.response.objects]);

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  const handleChangeFeature = (e: SelectOption) => {
    dispatch(setSelectedGroupFeature({
      ...selectedGroupFeature,
      feature: allFeatures.find((feature) => feature.id === e.value) as FeatureType,
      value: null,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {  
    e.preventDefault();  
    addFeatureMutation({
      variables: {
        etimFeature: selectedGroupFeature?.feature?.id as string,
        value: JSON.stringify(selectedGroupFeature?.value),
        group: activeQuotationGroupId, 
        id: selectedGroupFeature?.id as string,
      },
    });
    dispatch(setFeatureEdit());
  };

  useEffect(() => {
    if (mutationData && mutationData.groupFeature && mutationData.groupFeature.response) {  
      const message = selectedGroupFeature?.id ? t('Feature was successfully updated') : t('New feature was added');    
      dispatch(setSuccessAlert(
        [message],
      ));
      handleClose();
    }
  }, [mutationData, dispatch, handleClose, t, selectedGroupFeature?.id]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);
  
  const isValueArrayCorrect = !Array.isArray(selectedGroupFeature?.value) || (selectedGroupFeature?.value as number[])[0] <= (selectedGroupFeature?.value as number[])[1];
  const isSaveButtonDisabled = mutationLoading || selectedGroupFeature?.value === null || selectedGroupFeature?.value === undefined || !isValueArrayCorrect;

  return (
    <form className="w-full px-8" onSubmit={e => handleSubmit(e)}>
      <div className="w-full">
        <AsyncSelectInput
          placeholder={t('Select Feature')}
          onChange={(e) => {
            handleChangeFeature(e);
          }}
          loadOptions={loadFeaturesOptions}
          value={{
            value: selectedGroupFeature?.feature?.id as string,
            label: selectedGroupFeature?.feature?.localizedDescription as string,
          }
        }
        />
      </div>
      {
        selectedGroupFeature?.feature.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Numeric
        && (
          <div className="mt-2 w-full">
            <GroupFeatureNumericValue />
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Range
        && (
          <div className="mt-2 w-full">
            <GroupFeatureRangeValue />
            {!isValueArrayCorrect ? <p className="text-red-500 text-xs h-6">{t('The minimum value must be less or equal to the maximum value')}</p> : <p className='h-6'></p>}
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Alphanumeric
        && (
          <div className="mt-2 w-full">
            <GroupFeatureANValue />
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Logical
        && (
          <div className="mt-2 w-full">
            <GroupFeatureLogicalValue />
          </div>
        )
      }

      <div className="mt-60 mb-10 flex items-center justify-center w-full gap-x-8 ">
        <button
          type="button"
          onClick={() => {
            dispatch(setFeatureEdit());
            handleClose();
          }}
          className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
        >
          {t('Cancel')}
        </button>
        <button
          type="submit"
          disabled={isSaveButtonDisabled}
          className={classNames(isSaveButtonDisabled ? 'bg-cgray-300' : 'bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100', 'text-white w-1/5 flex justify-center py-2 px-4 rounded text-sm font-medium uppercase')}
        >
          {t('Save')}
        </button>
      </div>
    </form>

  );
}
